<form (ngSubmit)="onSubmit()" #editForm="ngForm">
    <!-- header -->
    <div class="detail-header-row">
        <h1 class="detail-header">{{element["project_id"]}} | {{element["project_title"]}}
        </h1>
        <div class="detail-buttons">
            <button mat-icon-button type="submit" (click)="closeExpandedRow()">
                <mat-icon aria-hidden="false" aria-label="Close details">close</mat-icon>
            </button>
            <button mat-icon-button type="button" (click)="toggleFullscreen($event)">
                <mat-icon aria-hidden="false" aria-label="Fullscreen">fullscreen</mat-icon>
            </button>
            <button mat-icon-button type="submit">
                <mat-icon aria-hidden="false" aria-label="Save changes">save</mat-icon>
            </button>
            <button mat-icon-button type="button" (click)="discardChanges()">
                <mat-icon aria-hidden="false" aria-label="Discard changes">delete</mat-icon>
            </button>
        </div>
    </div>

    <!-- mini details -->
    <div class="form-group mini-detail-row">
        <span class="left-detail" style="padding-right: 26px;">Radar segment:
        </span>
        <div class="center-detail">
            <mat-select class="form-control" id="radarSegment" [(ngModel)]="element['radar_segment']" name="radarSegment"
                #radarSegment="ngModel">
                <mat-option *ngFor="let segment of options['options']['radar_segment']" [value]="segment">{{segment}}</mat-option>
            </mat-select>
        </div>
        <span class="center-detail" style="padding-right: 26px; padding-left: 26px;">Org:
        </span>
        <div class="center-detail">
            <mat-select class="form-control" id="org" [(ngModel)]="element['org']" name="org" #org="ngModel">
                <mat-option *ngFor="let org of options['options']['org']" [value]="org">{{org}}</mat-option>
            </mat-select>
        </div>
        <span class="center-detail" style="padding-right: 26px; padding-left: 26px;">Point
            of Contact: </span>
        <mat-form-field class="center-detail">
            <textarea matInput type="text" class="form-control" id="firstPointOfContact"
                [(ngModel)]="element['first_point_of_contact']" name="firstPointOfContact"
                #firstPointOfContact="ngModel">{{element["first_point_of_contact"]}}</textarea>
        </mat-form-field>
        <span class="center-detail" style="padding-right: 26px; padding-left: 26px;">Status:
        </span>
        <div class="center-detail">
            <mat-select class="form-control" id="status" [(ngModel)]="element['status']" name="status" #status="ngModel">
                <mat-option *ngFor="let status of options['options']['status']" [value]="status">{{status}}</mat-option>
            </mat-select>
        </div>
    </div>

    <!-- project description -->
    <div class="project-description">
        <h2 style="margin-top: 0;">Project description</h2>
        <mat-form-field style="width: 100%">
            <textarea matInput style="min-height: 50px;" type="text" class="form-control" id="projectDescription"
                [(ngModel)]="element['project_description']" name="projectDescription"
                #projectDescription="ngModel">{{element["project_description"]}}</textarea>
        </mat-form-field>
    </div>

    <!-- situation -->
    <div class="situation-row">
        <div class="situation" style="padding-left: 0px;">
            <h3 class="situation-header">Initial Situation</h3>
        </div>
        <div class="situation">
            <h3 class="situation-header">Solution</h3>
        </div>
        <div class="situation">
            <h3 class="situation-header">Situation after implementation</h3>
        </div>
    </div>

    <div class="situation-detail-row">
        <div class="situation-detail" style="margin-right: 16px;">
            <mat-form-field style="width: 100%;">
                <textarea style="min-height: 150px;" matInput type="text" class="form-control" id="initialSituation"
                    [(ngModel)]="element['initial_situation']" name="initialSituation"
                    #initialSituation="ngModel">{{element["initial_situation"]}}</textarea>
            </mat-form-field>
        </div>
        <div class="situation-detail" style="margin-left: 8px; margin-right: 8px;">
            <mat-form-field style="width: 100%;">
                <textarea style="min-height: 150px;" matInput type="text" class="form-control" id="solution"
                    [(ngModel)]="element['solution']" name="solution"
                    #solution="ngModel">{{element["solution"]}}</textarea>
            </mat-form-field>
        </div>
        <div class="situation-detail" style="margin-left: 16px;">
            <mat-form-field style="width: 100%;">
                <textarea style="min-height: 150px;" matInput type="text" class="form-control"
                    id="situationAfterImplementation" [(ngModel)]="element['situation_after_implementation']"
                    name="situationAfterImplementation"
                    #situationAfterImplementation="ngModel">{{element["situation_after_implementation"]}}</textarea>
            </mat-form-field>
        </div>
    </div>

    <mat-divider class="divider"></mat-divider>

    <!-- bottom row -->
    <div class="bottom-row">
        <div class="bottom-row-detail" style="margin-right: 16px;">
            <h3 style="margin-top: 0;">Responsible Team</h3>
            <mat-form-field style="width: 100%;">
                <textarea matInput style="min-height: 20px;" type="text" class="form-control" id="responsible"
                    [(ngModel)]="element['responsible']" name="responsible"
                    #responsible="ngModel">{{element["responsible"]}}</textarea>
            </mat-form-field><br />
            <mat-form-field style="width: 100%;">
                <textarea matInput style="min-height: 20px;" type="text" class="form-control" id="team"
                    [(ngModel)]="element['team']" name="team"
                    #team="ngModel">{{element["team"]}}</textarea>
            </mat-form-field>
        </div>
        <div class="bottom-row-detail" style="margin-left: 8px; margin-right: 8px;">
            <h3 style="margin-top: 0;">Tools used and resources needed</h3>
            <table style="width: 75%">
                <tr>
                    <td>Tools:</td>
                    <td>
                        <mat-form-field style="width: 100%;"><textarea matInput style="min-height: 20px;" type="text"
                                class="form-control" id="toolsUsed" [(ngModel)]="element['tools_used']" name="toolsUsed"
                                #toolsUsed="ngModel">{{element["tools_used"]}}</textarea>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>Resources needed:</td>
                    <td>
                        <mat-form-field style="width: 100%;"><textarea matInput style="min-height: 20px;" type="text"
                                class="form-control" id="resourcesNeeded" [(ngModel)]="element['resources_needed']"
                                name="resourcesNeeded"
                                #resourcesNeeded="ngModel">{{element["resources_needed"]}}</textarea>
                        </mat-form-field>
                    </td>
                </tr>
            </table>
        </div>
        <div class="bottom-row-detail" style="margin-left: 16px;">
            <h3 style="margin-top: 0;">Schedule</h3>
            <table style="width: 75%">
                <tr>
                    <td>Idea/Scouting:</td>
                    <td>
                        <mat-form-field appearance="fill">
                            <mat-label>Month and Year</mat-label>
                            <input matInput [matDatepicker]="ideadp" [formControl]="ideaDate"
                                (dateChange)="setEmptyDate('idea')">
                            <mat-datepicker-toggle matSuffix [for]="ideadp">
                            </mat-datepicker-toggle>
                            <mat-datepicker #ideadp [calendarHeaderComponent]="customHeader" startView="year"
                                (monthSelected)="chosenMonthHandler($event, ideadp, 'idea')"
                                panelClass="example-month-picker">
                            </mat-datepicker>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>In implementation:</td>
                    <td>
                        <mat-form-field appearance="fill">
                            <mat-label>Month and Year</mat-label>
                            <input matInput [matDatepicker]="implementationdp" [formControl]="inImplementationDate"
                                (dateChange)="setEmptyDate('inImplementation')">
                            <mat-datepicker-toggle matSuffix [for]="implementationdp">
                            </mat-datepicker-toggle>
                            <mat-datepicker #implementationdp [calendarHeaderComponent]="customHeader" startView="year"
                                (monthSelected)="chosenMonthHandler($event, implementationdp, 'inImplementation')"
                                panelClass="example-month-picker">
                            </mat-datepicker>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>Ready for Scale-up:</td>
                    <td>
                        <mat-form-field appearance="fill">
                            <mat-label>Month and Year</mat-label>
                            <input matInput [matDatepicker]="scaleUpdp" [formControl]="scaleUpDate"
                                (dateChange)="setEmptyDate('scaleUp')">
                            <mat-datepicker-toggle matSuffix [for]="scaleUpdp">
                            </mat-datepicker-toggle>
                            <mat-datepicker #scaleUpdp [calendarHeaderComponent]="customHeader" startView="year"
                                (monthSelected)="chosenMonthHandler($event, scaleUpdp, 'scaleUp')"
                                panelClass="example-month-picker">
                            </mat-datepicker>
                        </mat-form-field>
                    </td>
                </tr>
                <tr>
                    <td>Implemented:</td>
                    <td>
                        <mat-form-field appearance="fill">
                            <mat-label>Month and Year</mat-label>
                            <input matInput [matDatepicker]="implementeddp" [formControl]="implementedDate"
                                (dateChange)="setEmptyDate('implemented')">
                            <mat-datepicker-toggle matSuffix [for]="implementeddp">
                            </mat-datepicker-toggle>
                            <mat-datepicker #implementeddp [calendarHeaderComponent]="customHeader" startView="year"
                                (monthSelected)="chosenMonthHandler($event, implementeddp, 'implemented')"
                                panelClass="example-month-picker">
                            </mat-datepicker>
                        </mat-form-field>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</form>