<!-- chips list to select shown columns -->
<h3>Shown columns:</h3>
<div class="chip-list">
    <mat-chip-list #displayedList="cdkDropList" class="chip-list-1 drag-area" cdkDropList
        cdkDropListOrientation="horizontal" [cdkDropListConnectedTo]="[hiddenList]" [cdkDropListData]="displayedColumns"
        (cdkDropListDropped)="chipDrop($event)">
        <mat-chip class="chip-1" cdkDrag *ngFor="let column of displayedColumns" [removable]="true"
            (removed)="removeChip(column)">{{options['headers'][column]}} <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
    </mat-chip-list>
</div>
<h3>Available columns:</h3>
<div class="chip-list">
    <mat-chip-list #hiddenList="cdkDropList" class="chip-list-1 drag-area" cdkDropList
        cdkDropListOrientation="horizontal" [cdkDropListConnectedTo]="[displayedList]" [cdkDropListData]="hiddenColumns"
        (cdkDropListDropped)="chipDrop($event)">
        <mat-chip class="chip-1" cdkDrag *ngFor="let column of hiddenColumns" [removable]="false">
            {{options['headers'][column]}}
        </mat-chip>
    </mat-chip-list>
</div>

<!-- search box -->
<mat-form-field class="search-box" appearance="fill" color="primary">
    <mat-label #filter>Filter all columns</mat-label>
    <input matInput type="text" class="form-control" [(ngModel)]="columnFilter['all']" name="all"
        (keyup)="applyColumnFilter('all', $event)" placeholder="Ex. ium" #input>
</mat-form-field>

<!-- column filters - ->
<div>
    <mat-form-field *ngFor="let filter of displayedColumns" style="margin-left: 15px;">
        <mat-label>Filter {{options['headers'][filter]}}</mat-label>
        <input matInput type="text" class="form-control" [(ngModel)]="columnFilter[filter]" name="{{filter}}"
            (keyup)="applyColumnFilter(filter,$event)" *ngIf="!hasOptions(filter); else checkBoxList">
        <ng-template #checkBoxList>
            <mat-select [(ngModel)]="columnFilter[filter]" multiple
                (selectionChange)="applyColumnFilter(filter,$event)">
                <mat-option *ngFor="let option of options['options'][filter]" [value]="option">{{option}}</mat-option>
            </mat-select>
        </ng-template>
    </mat-form-field>
</div>
-->

<!-- data table -->
<div class="mat-elevation-z8 data-table-div" title="Click on row for details">
    <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows cdkDropList
        cdkDropListOrientation="horizontal" (cdkDropListDropped)="drop($event)">

        <!-- Iterate over headers to define columns. -->
        <ng-container *ngFor="let column of headers" matColumnDef="{{column}}">
            <th mat-header-cell mat-sort-header cdkDrag *matHeaderCellDef class="column-header">
                <div style="display: flex;">
                    {{options['headers'][column]}}
                    <!--
                    <i class="element-expand element-icon" (click)="expandColumn(column)" *ngIf="!expandedColumns.includes(column); else collapseIcon"></i>
                    <ng-template #collapseIcon>
                        <i class="element-collapse element-icon" (click)="expandColumn(column)"></i>
                    </ng-template>
                    -->
                </div>

                <div class="sort-button">
                    <!--
                    <i class="element-filter element-icon" (click)="showFilter($event, column)"></i>
                    -->
                    <button mat-icon-button (click)="showFilter($event, column)">
                        <i class="material-icons-round" *ngIf="doesColumnHaveFilter(column)">filter_alt</i>
                        <i class="material-icons-outlined" *ngIf="!doesColumnHaveFilter(column)">filter_alt</i>

                    </button>
                </div>

                <div class="mat-elevation-z8 filter-div" (click)="doNothing($event)"
                    [ngStyle]="{'background': isDark ? '#262a32' : '#ffffff', 'display': shownfilter === column ? 'block' : 'none'}">
                    <mat-form-field>
                        <mat-label>Filter {{options['headers'][column]}}</mat-label>
                        <input matInput type="text" class="form-control" [(ngModel)]="columnFilter[column]"
                            name="{{column}}" (keyup)="applyColumnFilter(column,$event)"
                            *ngIf="!hasOptions(column); else checkBoxList">
                        <ng-template #checkBoxList>
                            <mat-select [(ngModel)]="columnFilter[column]" multiple
                                (selectionChange)="applyColumnFilter(column,$event)">
                                <mat-option *ngFor="let option of options['options'][column]" [value]="option">
                                    {{option}}</mat-option>
                            </mat-select>
                        </ng-template>
                    </mat-form-field>
                </div>
            </th>
            <td mat-cell class="table-cell {{column}}" *matCellDef="let element">{{element[column]}}</td>
        </ng-container>

        <!-- Expanded Column - detail row is spanning all columns -->
        <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                <div class="project-detail"
                    [@detailExpand]="element['project_id'] === expanded ? 'expanded' : 'collapsed'"
                    *ngIf="element['project_id'] === expanded" title="">

                    <!-- Detail row -->
                    <div class="detail-row" *ngIf="!isEditing; else editRow">
                        <app-detail-row [element]="element" [options]="options" (onRowClose)="closeExpandedRow()">
                        </app-detail-row>
                    </div>
                    <ng-template #editRow>
                        <div class="detail-row">
                            <app-detail-row-edit [element]="element" [headers]="headers" [options]="options"
                                (onRowClose)="closeExpandedRow()">
                            </app-detail-row-edit>
                        </div>
                    </ng-template>
                </div>
            </td>
        </ng-container>

        <!-- Sticky header row -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

        <!-- Normal row, (un)sets expanded element on click -->
        <tr mat-row *matRowDef="let element; columns: displayedColumns;" class="project-row"
            [class.project-expanded-row]="expanded === element['project_id']"
            (click)="toggleExpansion(element['project_id'])"></tr>

        <!-- Detailed row -->
        <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="expand-row"></tr>

        <!-- No data row if filter does not return any results -->
        <tr class="mat-row" *matNoDataRow>
            <td mat-cell [attr.colspan]="displayedColumns.length">No data matching the search tearms</td>
        </tr>
    </table>
</div>
<mat-paginator class="mat-elevation-z8" [pageSizeOptions]="[10, 15, 25, 50, 100]" showFirstLastButtons
    (page)="onPageChange($event)">
</mat-paginator>