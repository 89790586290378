import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { SiOUXThemeSwitcherModule } from '@simpl/sioux-ng';

import { CdkColumnDef } from '@angular/cdk/table';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { DataTableComponent } from './data-table/data-table.component';
import { DetailRowComponent } from './detail-row/detail-row.component';
import { DetailRowEditComponent, ExampleHeader } from './detail-row-edit/detail-row-edit.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    DataTableComponent,
    DetailRowComponent,
    DetailRowEditComponent,
    ExampleHeader,
    SnackBarComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    DragDropModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    SiOUXThemeSwitcherModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  bootstrap: [AppComponent],
  providers: [
    CdkColumnDef
  ]
})
export class AppModule { }
