import { Component, Inject } from '@angular/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

@Component({
  selector: 'app-snack-bar',
  templateUrl: './snack-bar.component.html',
  styleUrls: ['./snack-bar.component.scss']
})
export class SnackBarComponent {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any) { }

  backgroundColor() {
    if (this.data['style'] == 'error')
      return "#FF3131";
    if (this.data['style'] == "succeed")
      return '#27AA4C'
    if(this.data['isDark'])
      return '#11151e'
    return '#e7ecf9'
  }
}
