import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommunicationService {
  // Address of API Gateway
  private radarUrl = 'https://ffgsp23kqc.execute-api.eu-west-1.amazonaws.com/test/';

  // data and headers
  private data = new ReplaySubject<JSON[]>(1);
  private headers = new ReplaySubject<string[]>(1);
  private options = new ReplaySubject<JSON>(1);

  // snackbar messages
  private message = new Subject<any>();

  // editing mode
  private isEditing = new ReplaySubject<boolean>(1);

  // save which element is expanded, saving ID
  private expanded = new ReplaySubject<string>(1);

  data$ = this.data.asObservable();
  headers$ = this.headers.asObservable();
  isEditing$ = this.isEditing.asObservable();
  expanded$ = this.expanded.asObservable();
  options$ = this.options.asObservable();
  message$ = this.message.asObservable();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };

  constructor(
    private http: HttpClient,
  ) { }

  getData() {
    this.getOptions().then((o: any) => {

      // GET data
      let url = this.radarUrl + 'data';

      // getting an s3 pre-signed url returned
      // might have to change to pre-signed cloudfront url?
      this.http.get(url).subscribe((s3url: any) => {
        //console.log("This is s3 url", s3url['body'])

        // get data from pre-signed S3 url
        this.http.get(s3url['body'].slice(1, -1), { responseType: 'text' }).subscribe((data: any) => {
          var re = new RegExp(/(("[\s\S]*?")+?,){22}("[\s\S]*?")+?\n/gm);
          // each arr entry = one data entry in the csv (incl headers) 
          var lines = data.match(re);
          //console.log(lines);
          var linere = new RegExp(/"[\s\S]*?"/g);
          var headers = lines[0].match(linere);
          headers.forEach(function (line: string, index: number, arr: string[]) {
            arr[index] = line.substring(1, line.length - 1);
          });
          //console.log(headers);
          var tempdata: JSON[] = [];

          for (var i = 1; i < lines.length; i++) {
            var obj: { [key: string]: any[] } = {};
            // match each entry
            var currentLine = lines[i].match(linere);
            //console.log(currentLine)
            // because we use match instead of split, we have to remove " and ",
            currentLine.forEach(function (line: string, index: number, arr: string[]) {
              var l = line.substring(1, line.length - 1);
              for (var j = 0; j < o['decode'].length; j++) {
                l = l.replace(new RegExp(o['decode'][j]['search'], "g"), o['decode'][j]['replace']);
              }
              arr[index] = l;
            });

            for (var j = 0; j < headers.length; j++) {
              obj[headers[j]] = currentLine[j];
            }

            tempdata.push(JSON.parse(JSON.stringify(obj)));
          }

          //console.log(JSON.stringify(tempdata)),
          this.data.next(tempdata);
          this.headers.next(headers);
        })
      });
    });
  }

  getOptions() {
    return new Promise((resolve, reject) => {
      let url = this.radarUrl + "options";
      var o;

      // getting an s3 pre-signed url returned
      // might have to change to pre-signed cloudfront url?
      this.http.get(url).subscribe((s3url: any) => {
        //console.log("This is s3 url", s3url['body'])

        // get data from pre-signed S3 url
        this.http.get(s3url['body'].slice(1, -1), { responseType: 'text' }).subscribe((data: string) => {
          o = JSON.parse(data);
          this.options.next(o);
          resolve(o);
        })
      });

    });
  }

  setEditMode(mode: boolean) {
    this.isEditing.next(mode);
  }

  setExpanded(id: string) {
    this.expanded.next(id);
  }

  postData(element: any, options: any): void {
    const keys = Object.keys(element);
    var data: any = {};

    keys.forEach(key => {
      data[key] = element[key];
      for (var i = 0; i < options['encode'].length; i++) {
        data[key] = data[key].replace(new RegExp(options['encode'][i]['search'], "g"), options['encode'][i]['replace']);
      }
    });

    let url = this.radarUrl + "data"
    this.http.post<any>(url, data, this.httpOptions).subscribe(data => {
      console.log(data)
      var body = JSON.parse(data['body'])

      console.log("posted data", body)

      if (body['status'] == 'SUCCEEDED') {
        element['version'] = body['version'];
        this.message.next({"message": "Changes updated successfully", "style": "success", "icon": "check_circle"});
      } else {
        this.message.next({"message": "There was an error updateing the data", "style": "error", "icon": "error"});
      }
    });
  }

  jsonStringToArray(jsonString: string): JSON[] {
    /**
     *  converts a JSON string to an Array, containing individual JSON objects
     */

    // parse JSON string to JSON object
    const t = JSON.parse(jsonString);
    let arr: JSON[] = [];

    // loop over Array in JSON object and push each entry into an Array of Type JSON-Object
    for (var key in t) {
      if (t.hasOwnProperty(key)) {
        arr.push(t[key]);
      }
    }

    return arr;
  }

  // TODO: clean up code down here
  csvStringToArray(csvDataString: string, delimiter: string) {
    // based on https://github.com/tofiqquadri/ngx-csv-parser
    const regexPattern = new RegExp((`(\\${delimiter}|\\r?\\n|\\r|^)(?:\"((?:\\\\.|\"\"|[^\\\\\"])*)\"|([^\\${delimiter}\"\\r\\n]*))`), "gi")
    let matchedPatternArray = regexPattern.exec(csvDataString);
    const resultCSV: any[] = [[]];
    while (matchedPatternArray) {
      if (matchedPatternArray[1].length && matchedPatternArray[1] !== delimiter) {
        resultCSV.push([]);
      }
      const cleanValue = matchedPatternArray[2] ? matchedPatternArray[2].replace(new RegExp("[\\\\\"](.)", "g"), '$1') : matchedPatternArray[3];
      resultCSV[resultCSV.length - 1].push(cleanValue);
      matchedPatternArray = regexPattern.exec(csvDataString);
    }
    console.log(resultCSV);
    return resultCSV;
  }
}
