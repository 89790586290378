import { EventEmitter } from '@angular/core';
import { Component, OnInit, Input, Output } from '@angular/core';
import { ThemeNotifierService } from '@simpl/sioux-ng';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../communication.service';

@Component({
  selector: 'app-detail-row',
  templateUrl: './detail-row.component.html',
  styleUrls: ['./detail-row.component.scss'],
})
export class DetailRowComponent implements OnInit {
  isDark: boolean = true;                 // saving theme    
  themeSubscription: Subscription;        // subscribe to theme

  constructor(
    themeNotifier: ThemeNotifierService,
    private communication: CommunicationService
    ) {
    this.themeSubscription = themeNotifier.themeChanged$.subscribe(
      theme => {
        if (theme.isDark) {
          this.isDark = true;
        } else {
          this.isDark = false;
        }      }
    )
   }

  @Input() element: any;
  @Input() options: any;
  @Output() onRowClose = new EventEmitter<any>();

  ngOnInit(): void {
  }

  toggleFullscreen(event: MouseEvent): void {
    if (
      document.fullscreenElement
    ) {
      const elems = event.composedPath();
      for (let i = 0; i < elems.length; i++) {
        if ((elems[i] as HTMLElement).classList.contains('detail-row')) {
          (elems[i] as HTMLElement).style.backgroundColor = "";
          break;
        }
      }

      document.exitFullscreen();
    } else {
      const elems = event.composedPath();

      for (let i = 0; i < elems.length; i++) {
        if ((elems[i] as HTMLElement).classList.contains('detail-row')) {
          if (this.isDark)
            (elems[i] as HTMLElement).style.backgroundColor = "#2e3139";
          else
            (elems[i] as HTMLElement).style.backgroundColor = "#fbfbfb";

          (elems[i] as HTMLElement).requestFullscreen();

          break;
        }
      }
    }
  }

  toggleEditing() {
    this.communication.setEditMode(true);
  }

  closeExpandedRow() {
    this.onRowClose.emit(null);
  }
}
