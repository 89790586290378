<!-- header -->
<div class="detail-header-row">
    <h1 class="detail-header">{{element["project_id"]}} | {{element["project_title"]}}
    </h1>
    <div class="detail-buttons">
        <button mat-icon-button (click)="closeExpandedRow()">
            <mat-icon aria-hidden="false" aria-label="Close details">close</mat-icon>
        </button>
        <button mat-icon-button (click)="toggleFullscreen($event)">
            <mat-icon aria-hidden="false" aria-label="Fullscreen">fullscreen</mat-icon>
        </button>
        <button mat-icon-button (click)="toggleEditing()">
            <mat-icon aria-hidden="false" aria-label="Edit details">edit</mat-icon>
        </button>
    </div>
</div>

<!-- mini details -->
<div class="mini-detail-row">
    <span class="left-detail">Radar segment:
        {{element["radar_segment"]}}</span>
    <span class="center-detail">Org: {{element["org"]}}
    </span>
    <span class="center-detail">First Point of Contact:
        {{element["first_point_of_contact"]}}</span>
    <span class="right-detail">Status:
        {{element["status"]}}</span>
</div>

<!-- project description -->
<div class="project-description">
    <h2 style="margin-top: 0;">Project description</h2>
    <span style="white-space: pre-line;">{{element["project_description"]}}</span>
</div>

<!-- situation -->
<div class="situation-row">
    <div class="situation" style="padding-left: 0px;">
        <h3 class="situation-header">Initial Situation</h3>
    </div>
    <div class="situation">
        <h3 class="situation-header">Solution</h3>
    </div>
    <div class="situation">
        <h3 class="situation-header">Situation after implementation</h3>
    </div>
</div>

<div class="situation-detail-row">
    <span class="situation-detail" style="margin-right: 16px; ">{{element["initial_situation"]}}</span>
    <span class="situation-detail" style="margin-left: 8px; margin-right: 8px;">{{element["solution"]}}</span>
    <span class="situation-detail" style="margin-left: 16px;">{{element["situation_after_implementation"]}}</span>
</div>

<mat-divider class="divider"></mat-divider>

<!-- bottom row -->
<div class="bottom-row">
    <div class="bottom-row-detail" style="margin-right: 16px;">
        <h3 style="margin-top: 0;">Responsible Team</h3>
        <span>{{element["responsible"]}}</span><br />
        <span>{{element["team"]}}</span>
    </div>
    <div class="bottom-row-detail" style="margin-left: 8px; margin-right: 8px;">
        <h3 style="margin-top: 0;">Tools used and resources needed</h3>
        <table style="width: 75%">
            <tr>
                <td>Tools:</td>
                <td>{{element["tools_used"]}}</td>
            </tr>
            <tr>
                <td>Resources needed:</td>
                <td>{{element["resources_needed"]}}</td>
            </tr>
        </table>
    </div>
    <div class="bottom-row-detail" style="margin-left: 16px;">
        <h3 style="margin-top: 0;">Schedule</h3>
        <table style="width: 75%">
            <tr>
                <td>Idea/Scouting:</td>
                <td>{{element["scouting"]}}</td>
            </tr>
            <tr>
                <td>In implementation:</td>
                <td>{{element["in_implementation"]}}</td>
            </tr>
            <tr>
                <td>Ready for Scale-up:</td>
                <td>{{element["ready_for_scale_up"]}}</td>
            </tr>
            <tr>
                <td>Implemented:</td>
                <td>{{element["implemented"]}}</td>
            </tr>
        </table>
    </div>
</div>