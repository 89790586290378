import { Component, ElementRef, OnInit, ViewChild, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../communication.service';
import { ThemeNotifierService } from '@simpl/sioux-ng';

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class DataTableComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = [];                                              // columns to be displayed
  hiddenColumns: string[] = [];                                                 // columns not to be displayed
  dataSource: MatTableDataSource<JSON> = new MatTableDataSource<JSON>([]);      // data of the table
  headers: string[] = [];                                                       // all available header
  expandedElement: JSON | null = null;                                          // saving currently expanded row
  expandedColumns: string[] = [];                                               // saving expanded columns to expand them after page change
  isDark: boolean = true;                                                       // saving current theme
  isEditing: boolean = false;                                                   // toggle editing mode
  expanded: string = "";                                                        // id of expanded element
  options: any;                                                                 // options for header etc. 
  columnFilter: any = {};
  shownfilter: string = "";

  private paginator: MatPaginator;                                              // save paginator as variable
  @ViewChild(MatPaginator) set matPaginator(mp: MatPaginator) {                 // update variable and datasource
    this.paginator = mp;                                                        // workaround because table is in ngIf
    this.dataSource.paginator = this.paginator;
  };
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('filter') filter: ElementRef;                                      // filter reference to scroll up to on page change
  @Output() pageChange = new EventEmitter<any>();

  headersSubscription: Subscription;                                            // subscription to get headers from communication service
  dataSubscription: Subscription;                                               // subscription to get data from communication service
  themeSubscription: Subscription;                                              // subscription to save current theme
  isEditingSubscription: Subscription;                                          // subscribe to isEditing
  expandedSubscription: Subscription;
  optionsSubscription: Subscription;

  constructor(
    private communication: CommunicationService,
    private themeNotifier: ThemeNotifierService
  ) {
    // subscribe to headers Observable in communication service
    this.headersSubscription = communication.headers$.subscribe(
      headers => {
        this.headers = headers;
      }
    );

    // subscribe to data Observable in communication service
    this.dataSubscription = communication.data$.subscribe(
      data => {
        // update MatTableDataSource with Array of JSON objects
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      }
    );

    this.themeSubscription = themeNotifier.themeChanged$.subscribe(
      theme => {
        if (theme.isDark) {
          this.isDark = true;
        } else {
          this.isDark = false;
        }
      }
    );

    this.isEditingSubscription = communication.isEditing$.subscribe(
      mode => {
        this.isEditing = mode;
      }
    );

    this.expandedSubscription = communication.expanded$.subscribe(
      expanded => {
        this.expanded = expanded;
      }
    )

    this.optionsSubscription = communication.options$.subscribe(
      options => {
        this.options = options;
        this.displayedColumns = this.options['default_view'];
        this.hiddenColumns = this.headers.filter(e => !this.displayedColumns.includes(e));
      }
    )
  }
  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;

    // Overrride default filter behaviour of Material Datatable
    this.dataSource.filterPredicate = this.createColumnFilter();
  }

  ngOnInit(): void {
  }

  toggleFullscreen(event: MouseEvent): void {
    if (
      document.fullscreenElement
    ) {
      const elems = event.composedPath();
      for (let i = 0; i < elems.length; i++) {
        if ((elems[i] as HTMLElement).classList.contains('detail-row')) {
          (elems[i] as HTMLElement).style.backgroundColor = "";
          break;
        }
      }

      document.exitFullscreen();
    } else {
      const elems = event.composedPath();

      for (let i = 0; i < elems.length; i++) {
        if ((elems[i] as HTMLElement).classList.contains('detail-row')) {
          if (this.isDark)
            (elems[i] as HTMLElement).style.backgroundColor = "#2e3139";
          else
            (elems[i] as HTMLElement).style.backgroundColor = "#fbfbfb";

          (elems[i] as HTMLElement).requestFullscreen();

          break;
        }
      }
    }
  }

  chipDrop(event: CdkDragDrop<any>): void {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    }
  }

  removeChip(event: string): void {
    this.displayedColumns.splice(this.displayedColumns.indexOf(event), 1);
    this.hiddenColumns.push(event);
  }

  getTheme(): void {
    console.log(this.themeNotifier);
  }

  hasOptions(header: string) {
    return Object.keys(this.options['options']).includes(header);
  }

  applyFilter(event: Event): void {
    return;
    // applying filter to table
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyColumnFilter(filter: any, event: any) {
    this.dataSource.filter = JSON.stringify(this.columnFilter)
  }

  createColumnFilter() {
    let filterFunction = (data: any, filter: string): boolean => {
      let searchTerms = JSON.parse(filter);
      let isFilterSet = false;
      // check if there is anything to filter
      for (const col in searchTerms) {
        if (Object.keys(this.options['options']).includes(col)) {
          if (searchTerms[col].length > 0) {
            isFilterSet = true;
          } else {
            delete searchTerms[col];
          }
        } else {
          if (searchTerms[col].toString() !== '') {
            isFilterSet = true;
          } else {
            // if there is an empty string, delete the filter for this column
            delete searchTerms[col];
          }
        }
      }

      //console.log(searchTerms);

      let nameSearch = () => {
        let found = 0;
        if (isFilterSet) {
          for (const col in searchTerms) {
            let f = false;

            if (Object.keys(this.options['options']).includes(col)) {
              searchTerms[col].forEach((element: string) => {
                if (data[col].toString().toLowerCase().indexOf(element.toString().toLowerCase()) != -1 && isFilterSet) {
                  f = true;
                }
              });
            } else {
              searchTerms[col].trim().toLowerCase().split(' ').forEach((word: string) => {
                // if "all" filter is used, check all columns
                if (col === "all") {
                  for (const dataCol in data) {
                    if (data[dataCol].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
                      f = true;
                    }
                  }
                } else {
                  if (data[col].toString().toLowerCase().indexOf(word) != -1 && isFilterSet) {
                    f = true;
                  }
                }
              });
            }

            if (f)
              found += 1;
          }
          return found == Object.keys(searchTerms).length;
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    return filterFunction
  }

  drop(event: CdkDragDrop<string[]>) {
    // re-ordering table columns
    moveItemInArray(this.displayedColumns, event.previousIndex, event.currentIndex);
  }

  onPageChange(event: PageEvent) {
    // expand columns that were expanded before
    for (const c of this.expandedColumns) {
      const elems = document.getElementsByClassName(c);

      for (let i = 0; i < elems.length; i++) {
        elems[i].classList.remove('table-cell');
      }
    }

    // scroll up to beginning of table after changing page
    // only scroll if content is higher than window height
    this.pageChange.emit(null);
  }

  scrollUp() {
    this.filter.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  doNothing(event: Event) {
    event.stopPropagation();
  }

  showFilter(event: Event, filter: string) {
    event.stopPropagation();

    const outsideClickListener = (event: any) => {
      var isFilter = false;

      for (var i=0; i < event.path.length; i++) {
        if (!Object.keys(event.path[i]).includes('classList'))
          break;

        if (event.path[i].classList.contains('filter-div')) {
          isFilter = true;
          break;
        }

        if (event.path[i].classList.contains('content'))
          break;
      }

      if (!isFilter) { // or use: event.target.closest(selector) === null
        this.shownfilter = "";
        removeClickListener();
      }
    }

    const removeClickListener = () => {
      document.removeEventListener('click', outsideClickListener)
    }

    if (this.shownfilter === filter)
      this.shownfilter = "";
    else {
      this.shownfilter = filter;
      document.addEventListener('click', outsideClickListener)
    }
  }

  logStuff(event: Event) {
    console.log(event);
  }

  doesColumnHaveFilter(column: string) {
    if (!Object.keys(this.columnFilter).includes(column))
      return false;

    if (Object.keys(this.options['options']).includes(column)) {
      if (this.columnFilter[column].length > 0)
        return true;
      else
        return false;
    }

    if (this.columnFilter[column] == "")
      return false;

    return true;
  }

  expandColumn(name: string): void {
    const elems = document.getElementsByClassName(name);

    // check if column is currently expanded
    if (this.expandedColumns.includes(name)) {
      // remove classname from array
      this.expandedColumns.splice(this.expandedColumns.indexOf(name), 1);

      // set max-width to 100px
      for (let i = 0; i < elems.length; i++) {
        elems[i].classList.add('table-cell');
      }
    } else {
      // add classname to array
      this.expandedColumns.push(name);

      // un-set max-width
      for (let i = 0; i < elems.length; i++) {
        elems[i].classList.remove('table-cell');
      }
    }
  }

  logSort() {
    this.dataSource.sort = this.sort;
    console.log(this.dataSource.sort);
  }

  closeExpandedRow(): void {
    this.communication.setExpanded("");

    if (document.fullscreenElement)
      document.exitFullscreen();
  }

  toggleExpansion(id: string): void {
    if (this.expanded === id) {
      this.communication.setExpanded("");
      this.communication.setEditMode(false);
    }
    else
      this.communication.setExpanded(id);
  }
}
