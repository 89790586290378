import { NgModule } from '@angular/core';
import { MatModule } from './material/material.module';
import { TranslateModule } from '@ngx-translate/core';


@NgModule({
    imports: [
      MatModule,
      TranslateModule,
    ],
    exports: [
      MatModule, 
      TranslateModule,
    ]
  })
  export class SharedModule { }
