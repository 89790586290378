import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Inject, Input, Output, OnDestroy, EventEmitter } from '@angular/core';
import { ThemeNotifierService } from '@simpl/sioux-ng';
import { Subscription } from 'rxjs';
import { CommunicationService } from '../communication.service';
import { FormControl } from '@angular/forms';
import { MatCalendar } from "@angular/material/datepicker";
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM-YYYY',
  },
  display: {
    dateInput: 'MM-YYYY',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-detail-row-edit',
  templateUrl: './detail-row-edit.component.html',
  styleUrls: ['./detail-row-edit.component.scss'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class DetailRowEditComponent implements OnInit {
  isDark: boolean = true;                       // saving theme
  themeSubscription: Subscription;              // subscribe to theme
  backupElement: { [key: string]: any[] } = { };                           // backing up changes

  customHeader = ExampleHeader;
  ideaDate = new FormControl(null);
  inImplementationDate = new FormControl(null);
  scaleUpDate = new FormControl(null);
  implementedDate = new FormControl(null);

  constructor(
    themeNotifier: ThemeNotifierService,
    private communication: CommunicationService
  ) {
    this.themeSubscription = themeNotifier.themeChanged$.subscribe(
      theme => {
        if (theme.isDark) {
          this.isDark = true;
        } else {
          this.isDark = false;
        }
      }
    )
  }

  @Input() element: any;
  @Input() headers!: string[];
  @Input() options: any;
  @Output() onRowClose = new EventEmitter<any>();

  ngOnInit(): void {
    if (this.element['scouting'] !== "")
      this.ideaDate.setValue(moment(this.element['scouting'], 'MM-YYYY'));
    if (this.element['in_implementation'] !== "")
      this.inImplementationDate.setValue(moment(this.element['in_implementation'], 'MM-YYYY'));
    if (this.element['ready_for_scale_up'] !== "")
      this.scaleUpDate.setValue(moment(this.element['ready_for_scale_up'], 'MM-YYYY'));
    if (this.element['implemented'] !== "")
      this.implementedDate.setValue(moment(this.element['implemented'], 'MM-YYYY'));

    this.cloneElement();
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, control: string) {
    var ctrlValue;
    if (control === "idea")
      ctrlValue = this.ideaDate.value;
    else if (control === "inImplementation")
      ctrlValue = this.inImplementationDate.value;
    else if (control === "scaleUp")
      ctrlValue = this.scaleUpDate.value;
    else if (control === "implemented")
      ctrlValue = this.implementedDate.value;

    if (ctrlValue == null) {
      ctrlValue = moment().month(normalizedMonth.month());
      ctrlValue.year(normalizedMonth.year());
    }
    else {
      ctrlValue.month(normalizedMonth.month());
      ctrlValue.year(normalizedMonth.year());
    }

    if (control === "idea") {
      this.ideaDate.setValue(ctrlValue);
      this.element['scouting'] = this.ideaDate.value.format('MM-YYYY');
    }
    else if (control === "inImplementation") {
      this.inImplementationDate.setValue(ctrlValue);
      this.element['in_implementation'] = this.inImplementationDate.value.format('MM-YYYY');
    }
    else if (control === "scaleUp") {
      this.scaleUpDate.setValue(ctrlValue);
      this.element['ready_for_scale_up'] = this.scaleUpDate.value.format('MM-YYYY');
    }
    else if (control === "implemented") {
      this.implementedDate.setValue(ctrlValue);
      this.element['implemented'] = this.implementedDate.value.format('MM-YYYY');
    }

    datepicker.close();
  }

  setEmptyDate(control: string) {
    if (control === "idea") {
      this.ideaDate.setValue(null);
      this.element['scouting'] = "";
    }
    else if (control === "inImplementation") {
      this.inImplementationDate.setValue(null);
      this.element['in_implementation'] = "";
    }
    else if (control === "scaleUp") {
      this.scaleUpDate.setValue(null);
      this.element['ready_for-scale_up'] = "";
    }
    else if (control === "implemented") {
      this.implementedDate.setValue(null);
      this.element['implemented'] = "";
    }
  }

  toggleFullscreen(event: MouseEvent): void {
    if (
      document.fullscreenElement
    ) {
      const elems = event.composedPath();
      for (let i = 0; i < elems.length; i++) {
        if ((elems[i] as HTMLElement).classList.contains('detail-row')) {
          (elems[i] as HTMLElement).style.backgroundColor = "";
          break;
        }
      }

      document.exitFullscreen();
    } else {
      const elems = event.composedPath();

      for (let i = 0; i < elems.length; i++) {
        if ((elems[i] as HTMLElement).classList.contains('detail-row')) {
          if (this.isDark)
            (elems[i] as HTMLElement).style.backgroundColor = "#2e3139";
          else
            (elems[i] as HTMLElement).style.backgroundColor = "#fbfbfb";

          (elems[i] as HTMLElement).requestFullscreen();

          break;
        }
      }
    }
  }

  discardChanges() {
    this.restoreElement();
    this.communication.setEditMode(false);
  }

  closeExpandedRow() {
    this.onRowClose.emit(null);
  }

  onSubmit() {
    var difference = false;
    for (var i = 0; i < this.headers.length; i++) {
      if (this.element[this.headers[i]] != this.backupElement[this.headers[i]]) {
        difference = true;
        break;
      }
    }

    this.communication.setEditMode(false);
    if (difference)
      this.communication.postData(this.element, this.options);
  }

  restoreElement(): void {
    this.headers.forEach(x => this.element[x] = this.backupElement[x]);
  }

  cloneElement(): void {
    this.headers.forEach(x => this.backupElement[x] = this.element[x]);
  }
}

/** Custom header component for datepicker. */
@Component({
  selector: "example-header",
  styles: [
    `
      .example-header {
        display: flex;
        align-items: center;
        padding: 0.5em;
      }

      .example-header-label {
        flex: 1;
        height: 1em;
        font-weight: 500;
        text-align: center;
      }

      .example-double-arrow .mat-icon {
        margin: -22%;
      }
    `
  ],
  template: `
    <div class="example-header">
      <button mat-icon-button (click)="previousClicked('month')">
        <mat-icon>keyboard_arrow_left</mat-icon>
      </button>
      <span class="example-header-label">{{ periodLabel }}</span>
      <button mat-icon-button (click)="nextClicked('month')">
        <mat-icon>keyboard_arrow_right</mat-icon>
      </button>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExampleHeader<D> implements OnDestroy {
  private _destroyed = new Subject<void>();

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  get periodLabel() {
    return this._dateAdapter
      .format(
        this._calendar.activeDate,
        this._dateFormats.display.monthYearLabel
      )
      .toLocaleUpperCase();
  }

  previousClicked(mode: any) {
    this._calendar.activeDate = this._dateAdapter.addCalendarYears(
      this._calendar.activeDate,
      -1
    );
  }

  nextClicked(mode: any) {
    this._calendar.activeDate = this._dateAdapter.addCalendarYears(
      this._calendar.activeDate,
      1
    );
  }
}
