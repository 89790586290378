import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { LocalisationService } from './shared/localisation.service';
import { CommunicationService } from './communication.service';
import { Subscription } from 'rxjs';
import { ThemeNotifierService } from '@simpl/sioux-ng';
import { DataTableComponent } from './data-table/data-table.component';
import {MatSnackBar} from '@angular/material/snack-bar';
import { SnackBarComponent } from './snack-bar/snack-bar.component';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  data: JSON[] = [];                    // data                  
  isDark: boolean = true;               // saving current theme

  dataSubscription: Subscription;       // subscription to get data from communication service
  themeSubscription: Subscription;      // subscription to save current theme
  messageSubscription: Subscription;    // subscribe to messages

  readonly languages = [
    { name: 'LANGUAGE.EN', value: 'en' },
    { name: 'LANGUAGE.DE', value: 'de' }
  ];

  selectedValue: string;
  @ViewChild('content') content: ElementRef;
  @ViewChild(DataTableComponent) child: DataTableComponent;

  constructor(
    private translate: LocalisationService,
    private communication: CommunicationService,
    private snackbar: MatSnackBar,
    themeNotifier: ThemeNotifierService
  ) {
    // subscribe to data Observable in communication service
    this.dataSubscription = communication.data$.subscribe(
      data => {
        // update MatTableDataSource with Array of JSON objects
        this.data = data;
      }
    );

    this.themeSubscription = themeNotifier.themeChanged$.subscribe(
      theme => {
        if (theme.isDark) {
          this.isDark = true;
        } else {
          this.isDark = false;
        }
      }
    )

    this.messageSubscription = communication.message$.subscribe(
      message => {
        this.showSnackbar(message);
      }
    )

    //this.displayedColumns = theaders;
    this.selectedValue = translate.setLanguage();
  }

  showSnackbar(data: any) {
    var config = {duration: 4000, data: data, panelClass: "notif-standard"}
    if (data['style'] == "success")
      config.panelClass = "notif-success";
    if (data['style'] == "error")
      config.panelClass = "notif-error";

    console.log(config);

    this.snackbar.openFromComponent(SnackBarComponent, config);
  }

  isOutofView() {
    if (this.content.nativeElement.scrollHeight > this.content.nativeElement.clientHeight)
      this.child.scrollUp();
  }

  ngOnInit(): void {
    // load data on init
    this.communication.getData();
  }

  changeLanguage(): void {
    this.translate.setUserLanguage(this.selectedValue);
  }
}